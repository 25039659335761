import Component from '../core/Component'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'

const template = `
<div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg"></div>
    <div class="pswp__scroll-wrap">
        <div class="pswp__container">
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
            <div class="pswp__item"></div>
        </div>
        <div class="pswp__ui pswp__ui--hidden">
            <div class="pswp__top-bar">
                <div class="pswp__counter"></div>
                <a href="#" class="pswp__button pswp__button--close" data-no-swup title="Close (Esc)"></a>
                <button class="pswp__button pswp__button--share" data-no-swup title="Share"></button>
                <button class="pswp__button pswp__button--fs" data-no-swup title="Toggle fullscreen"></button>
                <button class="pswp__button pswp__button--zoom" data-no-swup title="Zoom in/out"></button>
                <div class="pswp__preloader">
                    <div class="pswp__preloader__icn">
                      <div class="pswp__preloader__cut">
                        <div class="pswp__preloader__donut"></div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                <div class="pswp__share-tooltip"></div>
            </div>
            <button class="pswp__button pswp__button--arrow--left" data-no-swup title="Previous (arrow left)">
            </button>
            <button class="pswp__button pswp__button--arrow--right" data-no-swup title="Next (arrow right)">
            </button>
            <div class="pswp__caption">
                <div class="pswp__caption__center"></div>
            </div>
        </div>
    </div>
</div>
`;

const buffer  = document.createElement('div')
buffer.innerHTML = template
document.body.appendChild(buffer)

const galleryRoot = buffer.querySelector('.pswp')

export default class Lightbox extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            open: null,
            links: [],
        }
    }

    prepare() {
        this.ref.links.forEach(link => link.addEventListener('click', ::this.handleClick))
        if (this.ref.open) {
            this.ref.open.addEventListener('click', ::this.handleClick)
        }
    }

    handleClick = async event => {
        event.preventDefault()
        document.documentElement.classList.add('has-lightbox')

        const galleryTrigger = event.currentTarget.closest('.ImageList')

        let items = []

        if (galleryTrigger){
            galleryTrigger.classList.add('is-loading')
            const data = await fetch(event.currentTarget.dataset.url).then(response => response.json())
        
            items = data.map(item => ({
                src: item.href,
                w: item['data-width'],
                h: item['data-height'],
                title: item['data-caption'],
            }))
        } else {
            items = this.ref.links
                .map(link => ({
                    src: link.href,
                    w: link.dataset.width,
                    h: link.dataset.height,
                    title: link.dataset.caption,
                }))
        }

        const index = this.ref.links.indexOf(event.currentTarget)
        const options = {
            index: Math.max(index, 0),
            shareEl: false,
            history: false,
            loop: false,
            clickToCloseNonZoomable: false,
        }

        this.gallery = new PhotoSwipe(galleryRoot, PhotoSwipeUI_Default, items, options)

        this.gallery.listen('beforeChange', function() {
            window.addEventListener("keydown", (event) => {
                if(event.keyCode === 37) {
                    if(this.getCurrentIndex() === 0) {
                        this.options.arrowKeys = false
                    } else {
                        this.options.arrowKeys = true
                    }
                }
                if(event.keyCode === 39) {
                    if(this.getCurrentIndex() + 1 === this.items.length) {
                        this.options.arrowKeys = false
                    } else {
                        this.options.arrowKeys = true
                    }
                }
            })
        });
        this.gallery.init()

        galleryTrigger?.classList.remove('is-loading')

        this.gallery.listen('destroy', () => {
            document.documentElement.classList.remove('has-lightbox')
            this.gallery = null
        });
    }
}