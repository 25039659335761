import Component from '../core/Component'
import PositionObserver from '../meta/PositionObserver'
import support from '../utils/BrowserSupport'
import { queryAll } from '../utils/dom'
import { queries } from '../core/config'
import enquire from 'enquire.js'

export const defaults = {
    percent: 0,
    pixels: 0,
    media: null,
    ratio: 1
}

export default class Parallax extends Component {
    constructor(element, options = {}) {
        super(element)

        this.options = {
            ...defaults,
            ...options
        }

        if (this.element.dataset.options) {
            const options = JSON.parse(this.element.dataset.options)

            this.options = {
                ...this.options,
                ...options
            }
        }

        if (this.element.dataset.percent) {
            this.options.percent = parseInt(this.element.dataset.percent)
        }

        if (this.element.dataset.pixels) {
            this.options.pixels = parseInt(this.element.dataset.pixels)
        }

        if (this.element.dataset.media) {
            this.options.media = this.element.dataset.media
        }

        if (this.element.dataset.ratio) {
            this.options.ratio = parseFloat(this.element.dataset.ratio)
        }

        this.images = queryAll('img:not(.is-lazy)', this.element)

        this.isActive = false
        this.canRender = false
        this.spread = 30

        this.enquireHandler = null

        if (this.options.media) {
            this.enquireQuery = this.options.media in queries ? queries[this.options.media] : this.options.media
            this.enquireHandler = {
                match: ::this.attach,
                unmatch: ::this.detach
            }
        } else {
            this.canRender = true
        }
    }

    prepare() {
        this.observer = new PositionObserver(this.element, {
            onRender: ::this.handleRender,
            onEnter: ::this.handleEnter,
            onLeave: ::this.handleLeave,
            onBeforeResize: ::this.handleOnBeforeResize,
            spread: this.calculateSpread()
        })

        if (this.element.tagName.toLowerCase() === 'img' || this.images.length) {
            this.waitForImages().then(() => {
                this.observer.resize()
            })
        }

        if (this.enquireHandler) {
            enquire.register(this.enquireQuery, this.enquireHandler)
        }
    }

    destroy() {
        this.observer.destroy()
        this.observer = null

        if (this.enquireHandler) {
            enquire.unregister(this.enquireQuery, this.enquireHandler)
        }
    }

    attach() {
        this.canRender = true
    }

    detach() {
        this.canRender = false
    }

    calculateSpread() {
        const box = this.element.getBoundingClientRect()
        const percentage = isNaN(this.options.percent) ? 0 : this.options.percent/100
        const pixels = this.options.pixels

        if (this.options.ratio === 1) {
            this.spread = box.height * percentage + pixels
        } else {
            this.spread = window.innerHeight * (this.options.ratio - 1) / 2
        }

        return this.spread
    }

    waitForImages() {
        const images = this.images.length ? this.images : this.element

        return Promise.all(images.map(element => {
            return new Promise((resolve, reject) => {
                const image = new Image()
                image.onload = () => resolve(element.src)
                image.src = element.src
            })
        }))
    }

    handleOnBeforeResize() {
        if (this.observer) {
            this.spread = this.calculateSpread()
            this.observer.options.spread = this.spread
        }
    }

    handleEnter({ ratio }) {
        this.isActive = true
    }

    handleLeave({ ratio }) {
        this.isActive = false
    }

    handleRender({ ratio }) {
        if (!this.isActive || !this.canRender) {
            return
        }

        this.element.style[support.transform] = `translate3d(0,${-ratio * this.spread}px,0)`
    }

}