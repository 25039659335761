import Component from '../core/Component'
import support from '../utils/BrowserSupport'
import { scrollToElement } from '../services/Scroll';

export const STATES = {
    CLICKABLE: 'is-clickable',
    OPEN: 'is-open',
    LOADING: 'is-loading',
    ACTIVE: 'is-active'
}

export default class TournamentAccordion extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            link: null,
            content: null,
            title: null,
            info:null,
            closeButtons:[],
            items: [],
            rows: [],
        }
    }

    prepare() {
        this.ref.rows.forEach(row => row.addEventListener('click', this.handleRowClick))
        this.ref.rows.forEach((item, index) => this.closeInfo(index, true))
        this.ref.closeButtons.forEach(closeButton => closeButton.addEventListener('click', this.handleCloseButtonClick))
    }

    handleResultDetailClick = ({url}) => {
        if (this.isActive) {
            this.changeContent(url)
        } else {
            this.options.url = url
            this.open()
        }
    }

    handleRowClick = event => {
        event.preventDefault()
        const index = this.ref.rows.indexOf(event.currentTarget)
        if (!~index) {
            return
        }
        this.toggleInfo(index)

        // update button url
        const target = this.ref.rows[index].querySelector('a')
        const itemInfo = this.ref.rows[index].nextElementSibling
        const headerButton = itemInfo.querySelector('.Button')
        headerButton.href = target.href
    }

    handleCloseButtonClick = event => {
        event.preventDefault()
        event.stopPropagation()

        const row = event.target.closest('.game-Table-rowWrap')?.querySelector('[data-ref="row"]')
        row.classList.remove(STATES.ACTIVE)
        const itemInfo = row.nextElementSibling
        this.hideDetailInfo(itemInfo)
    }

    updateElementContent(src, target) {
        src.innerHTML = target.innerHTML
    }

    toggleInfo(itemIndex) {
        const item = this.ref.rows[itemIndex]
        const itemInfo = item.nextElementSibling

        if (item.classList.contains(STATES.ACTIVE)) {
            this.closeInfo(itemIndex)
            // scrollToElement(this.ref.items[itemIndex])
        } else {
            this.openInfo(itemIndex)

            const parent = event.target.parentNode.parentNode
            this.updateElementContent(itemInfo.querySelector('.tournament-Info-Header-date'), parent.querySelector('.game-Table-time'))
            this.updateElementContent(itemInfo.querySelector('.tournament-Info-Header-price'), parent.querySelector('.game-Table-cell--gtd'))
            this.updateElementContent(itemInfo.querySelector('.tournament-Info-Header-about span'), parent.querySelector('.game-Table-cell--buyInWrap span:nth-child(2)'))
            this.updateElementContent(itemInfo.querySelector('.tournament-Info-Header-about i'), parent.querySelector('.game-Table-cell--stack'))
            this.updateElementContent(itemInfo.querySelector('.tournament-Info-Header-blinds i'), parent.querySelector('.game-Table-cell--reentry'))
            this.updateElementContent(itemInfo.querySelector('.tournament-Info-Header-blinds span'), parent.querySelector('.game-Table-cell--blinds'))
        }
    }

    openInfo(itemIndex) {
        const item = this.ref.rows[itemIndex]
        const itemInfo = item.nextElementSibling

        item.classList.add(STATES.ACTIVE)
        itemInfo.classList.add(STATES.ACTIVE)

        itemInfo.style.height = `calc(${itemInfo.scrollHeight}px + 5rem)`
        scrollToElement(itemInfo)

        this.ref.rows.forEach((item, index) => {
            if (index !== itemIndex) {
                this.closeInfo(index)
            }
        })
    }

    closeInfo(itemIndex, force = false) {
        const item = this.ref.rows[itemIndex]
        const itemInfo = item.nextElementSibling
        if (item.classList.contains(STATES.ACTIVE)) {
            item.classList.remove(STATES.ACTIVE)
            this.hideDetailInfo(itemInfo)
        }
    }

    hideDetailInfo(elem) {
        if (elem.classList.contains(STATES.ACTIVE)) {
            elem.classList.remove(STATES.ACTIVE)
            elem.style.height = '0px'
        }
    }
}
