import Component from '../core/Component'
import Scroll from '../services/Scroll'
import throttle from 'lodash/throttle'
import PositionObserver from '../meta/PositionObserver'
import { isWebGLAvailable } from '../utils/webgl'
import support from '../utils/BrowserSupport'
import enquire from 'enquire.js'
import { queries } from '../core/config'

function toRad(deg) {
    return Math.PI / 180 * deg
}

let THREE = null
let GLTFLoader = null

const WEBGL_SUPPORT = isWebGLAvailable()
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

let CAN_RENDER = true

enquire.register(queries.smallWideMax, {
    match: () => {
        CAN_RENDER = iOS
    },
    unmatch: () => {
        CAN_RENDER = true
    }
})

function promisifyLoader(loader, onProgress) {
    function promiseLoader(url) {
        return new Promise((resolve, reject) => {
            loader.load(url, resolve, onProgress, reject)
        })
    }

    return {
        originalLoader: loader,
        load: promiseLoader
    }
}

export const defaults = {
    spreadX: 270,
    spreadY: 0,
    spreadZ: 0,
    baseX: 0,
    baseY: 0,
    baseZ: -30,
    color: 'red',
    ratio: 1,
    detached: false,
    mobileFriendly: false
}

export const STATES = {
    ANIMATED: 'is-animated',
    READY: 'is-ready'
}

export default class Chip extends Component {

    constructor(element, options = {}) {
        super(element)

        this.options = {...defaults, ...options}

        if (this.element.dataset.options) {
            this.options = {
                ...this.options,
                ...JSON.parse(this.element.dataset.options)
            }
        }

        if (this.element.dataset.color) {
            this.options.color = this.element.dataset.color
        }

        if (this.element.dataset.ratio) {
            this.options.ratio = parseFloat(this.element.dataset.ratio)
        }

        this.canRender = false
        this.spread = 0
        this.isReady = false
        this.isLoading = false
    }

    async prepare() {
        if (!WEBGL_SUPPORT || (this.options.mobileFriendly && !CAN_RENDER)) {
            return
        }

        if (this.isLoading) {
            return
        }

        this.isLoading = true

        this.element.classList.add(STATES.ANIMATED)

        if (!THREE) {
            THREE = await import('three')
            let { default: loader } = await import('three-gltf-loader')
            THREE.GLTFLoader = loader
            loader = null
        }

        if (!GLTFLoader) {
            GLTFLoader = promisifyLoader(new THREE.GLTFLoader())
        }

        this.assets = {
            gltf: await GLTFLoader.load(`/assets/frontend/img/chip/chip-${this.options.color}.gltf`)
        }

        if (!this.options.detached) {
            this.observer = new PositionObserver(this.element, {
                onEnter: this.handleEnter,
                onLeave: this.handleLeave,
                onRender: this.handleRender,
                onBeforeResize: this.handleResize,
                spread: this.calculateSpread()
            })
        }

        this.init()
        this.render()
    }

    destroy() {
        if (this.observer) {
            this.observer.detach()
            this.observer = null
        }

        if (this.renderer) {
            this.renderer.forceContextLoss()
            this.renderer.context = null
            this.renderer.domElement = null
        }

        this.element.classList.remove(STATES.ANIMATED)
    }

    handleEnter = () => {
        this.canRender = true
    }

    handleLeave = () => {
        this.canRender = false
    }

    handleRender = ({ ratio }) => {
        if (!this.canRender) {
            return
        }

        if (!this.chip) {
            return
        }

        this.chip.rotation.x = toRad(this.options.spreadX * ratio + this.options.baseX)
        this.chip.rotation.y = toRad(this.options.spreadY * ratio + this.options.baseY)
        this.chip.rotation.z = toRad(this.options.spreadZ * ratio + this.options.baseZ)

        this.render(ratio)
    }

    handleResize = () => {
        if (this.observer) {
            this.spread = this.calculateSpread()
            this.observer.options.spread = this.spread
        }

        this.resize()
    }

    init() {

        this.camera = new THREE.PerspectiveCamera(10, 1, 0.01, 1000)
        this.camera.position.z = 10
        this.scene = new THREE.Scene()

        const spotLight = new THREE.SpotLight(0x404040, 5)
        spotLight.position.set( 100, 500, 300 )
        this.scene.add( spotLight )

        const ambientLight = new THREE.AmbientLight( 0x303030 , 15 )
        this.scene.add(ambientLight)

        this.chip = this.assets.gltf.scene
        this.chip.scale.set(.43, .43, .43)
        this.chip.rotation.x = toRad(this.options.spreadX + this.options.baseX)
        this.chip.rotation.y = toRad(this.options.spreadY + this.options.baseY)
        this.chip.rotation.z = toRad(this.options.spreadZ + this.options.baseZ)

        this.scene.add(this.chip)

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true
        })

        this.resize()
        this.element.appendChild(this.renderer.domElement)

        if (this.observer) {
            this.observer.resize()
        }

        this.element.classList.add(STATES.READY)
        this.isReady = true
        this.isLoading = false
    }

    resize() {
        if (this.renderer) {
            const width = this.element.offsetWidth
            this.renderer.setSize(width, width)
        }
    }

    render = (ratio) => {
        this.renderer.render(this.scene, this.camera)
        this.element.style[support.transform] = `translate3d(0, ${this.spread * ratio}px, 0)`
    }

    calculateSpread() {
        const box = this.element.getBoundingClientRect()
        const percentage = isNaN(this.options.percent) ? 0 : this.options.percent/100
        const pixels = this.options.pixels

        if (this.options.ratio === 1) {
            this.spread = 0
        } else {
            this.spread = window.innerHeight * (this.options.ratio - 1) / 2
        }

        return this.spread
    }

}
