import Component from '../core/Component';
import { scrollToElement } from '../services/Scroll';

export default class FormScroll extends Component {

    prepare() {
        if ($.nette) {
            $.nette.ext('scroll', {
                success: function(data) {
                    scrollToElement(document.querySelector("#snippet--hotels"))
                }
            });
        }
    }

    destroy() {
        if ($.nette) {
            $.nette.ext('scroll', null)
        }
    }

}