import Component from '../core/Component';
import Cookies from 'js-cookie';
import { disableScrolling, enableScrolling } from '../services/Scroll';
import EventBus from '../core/EventBus';

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
}

export default class ModalAge extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            agree: null
        }
    }

    prepare() {
        if (!Cookies.get('cookiesAge')) {
            //disableScrolling();
            Cookies.set('cookietest', 1);
            if (Cookies.get('cookietest')) {
                Cookies.remove('cookietest');
                this.ref.agree.addEventListener('click', ::this.handleClick);
            }
        } else {
            this.hide();
        }
    }

    handleClick(event) {
        event.preventDefault();
        Cookies.set('cookiesAge', 1);
        this.hide();
    }

    hide() {
        EventBus.emit('mainVideo:start');
        document.documentElement.classList.remove('has-age-popup');
        this.element.classList.remove(STATUS.ACTIVE);
        setTimeout(() => {
            this.element.classList.remove(STATUS.VISIBLE);
        }, 200);
    }
}